<template>
    <p>V model: {{ vmodel }}</p>
    <Table
      v-if="!isLoading"
      showModalitiesNumber
      :headers="tableData.headers"
      :staticHeaders="tableData.staticHeaders"
      :items="tableData.items"
      :tools="false"
      simpleLayout
    >
    <template v-slot:headerAnnee >
      <SelectExtended
              id="annee"
              v-model="vmodel.annee"
              multiple
              search
              label="Année"
              optionKey="id"
              optionValue="valeur"
              :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
        'anneerecolte',
      )}/valeur`"
            />
    </template>
    </Table>

    <Table
      v-if="!isLoading"
      showModalitiesNumber
      :staticHeaders="tableData.staticHeaders"
      :items="tableData.items"
      :tools="false"
      simpleLayout
    />
  <!-- <button @click="isCollapsed = !isCollapsed">
    Toggle {{ isCollapsed }}
  </button> -->
  <!-- <collapse-transition> -->
  <!-- <Table
    :staticHeaders="staticHeaders"
    :headers="[]"
    :items="items"
    :hiddenItems="['ordre']"
    :tools="false"
  /> -->
  <!-- </collapse-transition> -->

  <div class="table-grouping-wrapper">
    <table class="table-grouping">
      <thead>
        <tr>
          <th></th>
          <template v-for="i in 10" :key="i">
            <th>
              <div class="th-container">
                <div class="th-title">Essais molluscicides</div>
                <div class="th-year">2021</div>
                <div class="th-coop">Groupe d'experts intergouvernemental sur l'évolution du climat</div>
              </div>
            </th>
            <th>
              <div class="th-title">Lorem ipsum dolor sit amet consectetur, adipisicing elit</div>
              <div class="th-year">2023</div>
              <div class="th-coop">Coopérative Diatem</div>
            </th>
            <th>
              <div class="th-title">Quibusdam reprehenderit praesentium.</div>
              <div class="th-year">2022</div>
              <div class="th-coop">COOP OSS</div>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="j in 50" :key="j">
          <tr>
            <th>
              <div class="th-container">
                <div class="th-button">
                  <button class="active" v-if="Math.random() > 0.3"></button>
                  <button class="inactive" v-else-if="Math.random() > 0.6"></button>
                  <button class="mixed" v-else></button>
                </div>
                <div class="th-infos">
                  <div class="th-id">[{{ j.toString().padStart(2, '0') }}]</div>
                  <div class="th-name" v-if="Math.random() > 0.5" title="Archivar">Archivar</div>
                  <div class="th-name" v-else title="Consectetur adipisicing elit">Consectetur adipisicing elit</div>
                </div>
              </div>
            </th>
            <template v-for="i in 30" :key="i">
              <td>
                <template v-if="Math.random() > 0.2">
                  <button :class="{'active': Math.random() > 0.5}"></button>
                </template>
              </td>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
  </div>

</template>

<script>
import Table from '@/components/table/Table.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
// import Table from '@/components/table/Table.vue'
// import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'

export default {
  name: 'StyleguideTableView',

  components: {
    SelectExtended,
    Table,
    // Table,
    // CollapseTransition,
  },

  data() {
    return {
      isLoading: true,
      vmodel: {
        annee: null,
      },
      tableData: {
        staticHeaders: [],
        headers: [],
        items: [],
      },
    }
  },

  mounted() {
    this.tableData.headers = [
      {
        designation: "Nom de l'onglet",
        sub_headers: [
          'id',
          'titre',
        ],
      },
      {
        designation: 'annee',
        slotName: 'headerAnnee',
        sub_headers: [
          'annee',
        ],
      },
    ]
    this.tableData.staticHeaders = [
      'Statut',
    ]
    this.tableData.items = []
    this.fetchService.get('protocole', { limit: 25 }).then((response) => {
      response.data.forEach((protocole) => {
        const item = {
          id: protocole.id,
          nom: protocole.titre,
          annee: protocole?.annee_recolte?.valeur,
          statut: protocole.statut.designation,
        }
        this.tableData.items.push(item)
      })
      this.isLoading = false
    })
    // this.setDatas()
  },

  methods: {

  },
}
</script>

<style lang="scss" scoped>
.table-grouping {
  $table-grouping-border-color: $color-gray-light;

  font-size: $font-size-small;
  line-height: $line-height-small;
  // border-collapse:collapse;
  // border: 2px solid $table-grouping-border-color;
  border-collapse: separate;
  border-spacing: 0;

  th,
  td {
    border: 1px solid $table-grouping-border-color;
    padding: 0.2rem;
  }

  th {
    font-weight: $font-weight-normal;
    background-color: white;
  }

  td {
    border-top: none;
    border-left: none;
  }

  thead {

    th {
      position: sticky;
      top: 0;
      z-index: +1;

      &:first-child {
        left: 0;
        z-index: +2;
        border-left-color: white;
        border-top-color: white;
      }

      &:not(:first-child) {
        padding: $gutter-quarter;
        border-left: none;
        writing-mode: vertical-rl;
        text-align: left;
        transform-origin: top left;
        transform: translate3d(calc(100% - 1px), 100%, 0) rotate(0.5turn/* + 0.125turn*/);
        max-height: 15rem;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        th {
          background-color: $color-gray-lightestest;
        }
      }

    }
    th {
      position: sticky;
      left: 0;
      border-top: none;
      max-width: 18rem;

      .th-container {
        display: flex;
        gap: $gutter-half;
        align-items: center;
        text-align: left;
      }

      .th-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    td {
      button {
        display: block;
        width: 100%;
        min-height: 2rem;
        border: none;
        background-color: $color-grouping-active;
        cursor: pointer;
        transition: all 0.125s;

        @include hocus() {
          background-color: $color-grouping-active-dark;
        }

        &.active {
          background-color: $color-grouping-inactive;

          @include hocus() {
            background-color: $color-grouping-inactive-dark;
          }
        }
      }

    }
  }
}

.th-infos {
  overflow: hidden;
  display: flex;
  gap: $gutter-half;
  align-items: center;
  text-align: left;
}

.th-id {
  font-family: $font-family-monospace;
}

.th-id,
.th-title {
  font-weight: $font-weight-semibold;
}

.th-coop {
  font-style: italic;
}

.th-coop,
.th-year {
  color: $color-gray-dark;
}

.th-button {
  button {
    display: block;
    width: 2rem;
    min-height: 2rem;
    border: none;
    cursor: pointer;
    transition: all 0.125s;

    &.active {
      background-color: $color-grouping-active;

      @include hocus() {
        background-color: $color-grouping-active-dark;
      }
    }
    &.inactive {
      background-color: $color-grouping-inactive;

      @include hocus() {
        background-color: $color-grouping-inactive-dark;
      }
    }
    &.mixed {
      background: linear-gradient(135deg,
        $color-grouping-active,
        $color-grouping-active 50%,
        $color-grouping-inactive 50%,
        $color-grouping-inactive
      );

      @include hocus() {
        background: linear-gradient(135deg,
          $color-grouping-active-dark,
          $color-grouping-active-dark 50%,
          $color-grouping-inactive-dark 50%,
          $color-grouping-inactive-dark
        );
      }
    }
  }
}

.table-grouping-wrapper {
  height: 100%;
  width: 100%;
  overflow: auto;
  @include custom-scrollbar();
}

</style>
